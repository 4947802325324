import { Heading, Stack } from "@chakra-ui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { graphql } from "gatsby"
import React from "react"
import { Seo } from "../components/seo"
import Teaser from "../components/teaser"

// https://www.gatsbyjs.com/blog/fs-route-api/
function Template({ data }) {
  const { page } = data

  return (
    <>
      <Seo title={page.title} description={page.summary} />
      <Stack spacing={5}>
        <Heading as="h1">
          <FontAwesomeIcon icon={["fas", page.icon]} />
          &nbsp;
          {page.title}
        </Heading>
        <Teaser nodes={page.serviceNodes} />
      </Stack>
    </>
  )
}

export const pageQuery = graphql`
  query ($id: String!) {
    page: leistungsbereicheYaml(id: { eq: $id }) {
      title
      icon
      serviceNodes {
        title
        teaser {
          childImageSharp {
            gatsbyImageData(width: 300, height: 300)
          }
        }
        fields {
          slug
        }
      }
    }
  }
`

export default Template
